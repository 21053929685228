/*!
 * @jdcfe/yep-react v0.2.28 (https://jdf2e.github.io/yep-react)
 * Copyright 2019 JDCF2E, Inc.
 * Licensed under the ISC license
 */
.Yep-action-sheet {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all .2s;
  transition: all .2s;
  background-color: #fff;
  text-align: center;
  font-size: 14px; }
  .Yep-action-sheet-content {
    overflow: hidden;
    background: #fff; }
  .Yep-action-sheet-list {
    list-style: none; }
  .Yep-action-sheet-cancel span, .Yep-action-sheet-item, .Yep-action-sheet-title {
    display: block;
    padding: 34px 32px;
    margin: 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #666;
    background-color: #fff; }
  .Yep-action-sheet-title {
    padding-top: 32px;
    padding-bottom: 32px;
    color: #333;
    font-size: 36px;
    position: relative; }
    .Yep-action-sheet-title:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-action-sheet-item {
    list-style: none;
    -webkit-user-select: none;
            user-select: none;
    position: relative; }
    .Yep-action-sheet-item_active {
      color: #ec3838; }
    .Yep-action-sheet-item:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-action-sheet-space {
    height: 12px;
    background-color: rgba(37, 38, 45, 0.4); }
  .Yep-action-sheet-cancel {
    background-color: #fff; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.zoom-enter {
  opacity: 0.01;
  -webkit-transform: scale(0);
          transform: scale(0); }

.zoom-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.zoom-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.zoom-exit-active {
  opacity: 0.01;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.slide-up-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.slide-up-enter-active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.slide-up-exit {
  -webkit-transform: translateY(0%);
          transform: translateY(0%); }

.slide-up-exit-active {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in; }

.slide-down-enter {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.slide-down-enter-active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.slide-down-exit {
  -webkit-transform: translateY(0%);
          transform: translateY(0%); }

.slide-down-exit-active {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in; }

.slide-right-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.slide-right-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.slide-right-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.slide-right-exit-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in; }

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  background-color: #f8f8f8;
  font-size: 28px;
  font-family: PingFangSC, "Microsoft YaHei", Helvetica, "Droid Sans", Arial, sans-serif; }

*[contenteditable] {
  -webkit-user-select: auto !important; }

input[readonly] {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

*:focus {
  outline: none; }

a {
  background: transparent;
  text-decoration: none;
  outline: none; }

.mask-open {
  overflow: hidden; }

.Yep-area-picker {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #fff;
  text-align: center;
  font-size: 14px; }
  .Yep-area-picker-title {
    font-size: 36px;
    color: #2e2d2d;
    font-weight: bold; }
    .Yep-area-picker-title-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      height: 80px;
      padding: 20px 20px 0 20px; }
  .Yep-area-picker-content {
    height: 956px;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .Yep-area-picker-content li {
      height: 74px;
      line-height: 74px;
      padding: 0 20px;
      color: #333;
      font-size: 24px;
      text-align: left;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center; }
      .Yep-area-picker-content li .Yep-area-picker-area--selected {
        margin-left: 30px;
        color: #ec3838; }

.Yep-area-picker-tabs-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1; }
  .Yep-area-picker-tabs-bar-tab {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 28px;
    height: 56px;
    line-height: 56px;
    margin-left: 20px; }
  .Yep-area-picker-tabs-bar-tab-active {
    color: #ec3838;
    position: relative; }
    .Yep-area-picker-tabs-bar-tab-active:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1PX;
      background-color: #ec3838;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-area-picker-tabs-bar-animated .Yep-area-picker-tabs-bar-content {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    will-change: transform; }
  .Yep-area-picker-tabs-bar-top {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .Yep-area-picker-tabs-bar-top .Yep-area-picker-tabs-bar-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
              flex-direction: row;
      position: relative; }
      .Yep-area-picker-tabs-bar-top .Yep-area-picker-tabs-bar-content:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 1PX;
        background-color: #e3e5e9;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        pointer-events: none; }
      .Yep-area-picker-tabs-bar-top .Yep-area-picker-tabs-bar-content .Yep-area-picker-tabs-bar-tab-active {
        color: #ec3838;
        position: relative; }
        .Yep-area-picker-tabs-bar-top .Yep-area-picker-tabs-bar-content .Yep-area-picker-tabs-bar-tab-active:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 1PX;
          background-color: #ec3838;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
          pointer-events: none; }

.Yep-auto-complete__popover .Yep-popover__content {
  max-width: none;
  padding: 0; }

.Yep-auto-complete__result {
  list-style: none;
  margin: 0;
  padding: 0; }
  .Yep-auto-complete__result > li {
    line-height: 30px;
    height: 30px;
    padding: 0 10px;
    cursor: pointer; }
    .Yep-auto-complete__result > li:hover {
      background-color: #f5f5f5; }

.Yep-auto-complete__result {
  max-height: 180px;
  overflow: auto; }

.Yep-auto-complete__option--active {
  background-color: #f5f5f5; }

.Yep-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle; }
  .Yep-badge-text {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: -12px;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    border-radius: 24px;
    padding: 0 10px;
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    background-color: #ed2945;
    white-space: nowrap;
    -webkit-transform: translateX(-45%);
            transform: translateX(-45%);
    -webkit-transform-origin: -10% center;
            transform-origin: -10% center;
    z-index: 10;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '\5FAE\8F6F\96C5\9ED1', SimSun, sans-serif; }
    .Yep-badge-text a {
      color: #ffffff; }
    .Yep-badge-text p {
      margin: 0;
      padding: 0; }
  .Yep-badge-dot {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
    top: -8px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #ed2945;
    z-index: 10; }
  .Yep-badge-not-a-wrapper .Yep-badge-text,
  .Yep-badge-not-a-wrapper .Yep-badge-dot {
    top: auto;
    display: block;
    position: relative;
    -webkit-transform: translateX(0);
            transform: translateX(0); }

.Yep-btn {
  display: block;
  outline: 0 none;
  padding: 0;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 4px;
  font-size: 36px;
  white-space: nowrap;
  -webkit-appearance: none;
  box-sizing: border-box;
  color: #3d3f52;
  background-color: #fff;
  border: 1PX solid #c6c7ce;
  border-radius: 4px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    html:not([data-scale]) .Yep-btn {
      position: relative;
      border: none; }
      html:not([data-scale]) .Yep-btn::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 200%;
        height: 200%;
        border: 1PX solid #c6c7ce;
        border-radius: 8px;
        -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
        box-sizing: border-box;
        pointer-events: none; } }
  .Yep-btn-borderfix:before {
    -webkit-transform: scale(0.49) !important;
            transform: scale(0.49) !important; }
  .Yep-btn.btn-disabled {
    background-color: #b5b5b5;
    border: 1PX solid #b5b5b5; }
  .Yep-btn.btn-primary {
    background-color: #ec3838;
    color: #fff;
    border: 1PX solid #ec3838;
    border-radius: 4px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      html:not([data-scale]) .Yep-btn.btn-primary {
        position: relative;
        border: none; }
        html:not([data-scale]) .Yep-btn.btn-primary::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 200%;
          height: 200%;
          border: 1PX solid #ec3838;
          border-radius: 8px;
          -webkit-transform-origin: 0 0;
                  transform-origin: 0 0;
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
          box-sizing: border-box;
          pointer-events: none; } }
    .Yep-btn.btn-primary.btn-disabled {
      background-color: #b5b5b5;
      border: 1PX solid #b5b5b5;
      border-radius: 4px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        html:not([data-scale]) .Yep-btn.btn-primary.btn-disabled {
          position: relative;
          border: none; }
          html:not([data-scale]) .Yep-btn.btn-primary.btn-disabled::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 200%;
            border: 1PX solid #b5b5b5;
            border-radius: 8px;
            -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
            box-sizing: border-box;
            pointer-events: none; } }
  .Yep-btn.btn-ghost {
    background-color: #fff;
    color: #ec3838;
    border: 1PX solid #ec3838;
    border-radius: 4px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      html:not([data-scale]) .Yep-btn.btn-ghost {
        position: relative;
        border: none; }
        html:not([data-scale]) .Yep-btn.btn-ghost::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 200%;
          height: 200%;
          border: 1PX solid #ec3838;
          border-radius: 8px;
          -webkit-transform-origin: 0 0;
                  transform-origin: 0 0;
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
          box-sizing: border-box;
          pointer-events: none; } }
    .Yep-btn.btn-ghost.btn-disabled {
      background-color: #b5b5b5;
      border: 1PX solid #b5b5b5;
      border-radius: 4px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        html:not([data-scale]) .Yep-btn.btn-ghost.btn-disabled {
          position: relative;
          border: none; }
          html:not([data-scale]) .Yep-btn.btn-ghost.btn-disabled::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 200%;
            border: 1PX solid #b5b5b5;
            border-radius: 8px;
            -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
            box-sizing: border-box;
            pointer-events: none; } }
  .Yep-btn.btn-inline {
    display: inline-block;
    padding: 0 30px; }
  .Yep-btn.btn-md {
    height: 100px;
    line-height: 100px;
    border-radius: 0px; }
  .Yep-btn.btn-sm {
    font-size: 26px;
    height: 56px;
    line-height: 56px;
    padding: 0 30px;
    border-radius: 4px; }
  .Yep-btn-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Yep-btn > .Yep-btn-icon {
    margin-right: 0.5em; }

.Yep-btns {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  .Yep-btns .Yep-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 20px; }
    .Yep-btns .Yep-btn:last-of-type {
      margin-right: 0; }

.Yep-carousel {
  position: relative;
  overflow: hidden; }
  .Yep-carousel__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }
  .Yep-carousel__item {
    display: inline-block;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .Yep-carousel__page {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    left: 0;
    right: 0;
    bottom: 0.25rem; }
    .Yep-carousel__page-bullet {
      width: 0.14rem;
      height: 0.14rem;
      margin: 0 0.055rem;
      border-radius: 0.07rem;
      background: rgba(255, 255, 255, 0.4); }
      .Yep-carousel__page-bullet.active {
        width: 0.24rem;
        background: #fff; }

.Yep-checkbox {
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  .Yep-checkbox-inner {
    left: 15px;
    width: 40px;
    height: 40px;
    border: 1PX solid #ccc;
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    box-sizing: border-box;
    margin-right: 20px; }
    .Yep-checkbox-inner:after {
      position: absolute;
      display: none;
      top: 5px;
      right: 10px;
      z-index: 999;
      width: 10px;
      height: 15px;
      border-style: solid;
      border-width: 0 4px 4px 0;
      border-radius: 2px;
      content: '\0020';
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .Yep-checkbox-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    -webkit-appearance: none;
            appearance: none; }
  .Yep-checkbox.Yep-checkbox-checked .Yep-checkbox-inner {
    border-color: #ec3838;
    background: #ec3838; }
    .Yep-checkbox.Yep-checkbox-checked .Yep-checkbox-inner:after {
      display: block;
      border-color: #fff; }
  .Yep-checkbox.Yep-checkbox-disabled {
    opacity: 0.3; }
    .Yep-checkbox.Yep-checkbox-disabled.Yep-checkbox-checked .Yep-checkbox-inner {
      border-color: #999baa;
      background: none; }
      .Yep-checkbox.Yep-checkbox-disabled.Yep-checkbox-checked .Yep-checkbox-inner:after {
        border-color: #999baa; }


@charset "UTF-8";
.Yep-dialog {
  position: relative;
  /* 如果没有header时，改变padding的值*/ }
  .Yep-dialog-transparent {
    width: 540px; }
  .Yep-dialog-content {
    position: relative;
    background-color: #ffffff;
    border: 0;
    text-align: center;
    height: 100%;
    overflow: hidden; }
  .Yep-dialog-transparent .Yep-dialog-content {
    border-radius: 18px;
    padding-top: 30px; }
  .Yep-dialog-header {
    padding: 12px 30px 30px; }
  .Yep-dialog-title {
    margin: 0;
    font-size: 36px;
    line-height: 1;
    color: #000;
    text-align: center; }
  .Yep-dialog-body {
    font-size: 32px;
    color: #888;
    height: 100%;
    line-height: 1.5;
    overflow: auto; }
  .Yep-dialog-transparent .Yep-dialog-content .Yep-dialog-header + .Yep-dialog-body {
    padding: 0 30px 30px; }
  .Yep-dialog-transparent .Yep-dialog-content .Yep-dialog-body {
    padding: 28px 30px 58px; }
  .Yep-dialog-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 222; }
  .Yep-dialog-button-group-h {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }
  .Yep-dialog-button-group-h .Yep-dialog-button {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    outline: none;
    background-color: #ec3838;
    color: #ffffff;
    font-size: 36px;
    height: 88px;
    line-height: 88px;
    display: block;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative; }
    .Yep-dialog-button-group-h .Yep-dialog-button:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-dialog-button:first-child {
    background-color: #ffffff;
    color: #000000; }

.Yep-dropdown-toggle {
  display: inline-block;
  vertical-align: middle; }

.Yep-icon {
  fill: currentColor;
  background-size: cover;
  width: 44px;
  height: 44px; }
  .Yep-icon-xxs {
    width: 30px;
    height: 30px; }
  .Yep-icon-xs {
    width: 36px;
    height: 36px; }
  .Yep-icon-sm {
    width: 42px;
    height: 42px; }
  .Yep-icon-md {
    width: 44px;
    height: 44px; }
  .Yep-icon-lg {
    width: 72px;
    height: 72px; }
  .Yep-icon-lego_jiazai {
    -webkit-animation: cirle-anim 1s linear infinite;
            animation: cirle-anim 1s linear infinite; }

@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Yep-indexed-list-section-header .Yep-list-item {
  height: 60px;
  min-height: 60px;
  background-color: #fff; }
  .Yep-indexed-list-section-header .Yep-list-item .Yep-list-line {
    height: 60px;
    min-height: 60px; }
  .Yep-indexed-list-section-header .Yep-list-item .Yep-list-content {
    font-size: 28px !important;
    color: #999baa !important; }

.Yep-indexed-list-indexed-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  text-align: center;
  color: #000;
  font-size: 32px;
  list-style: none;
  padding: 0; }
  .Yep-indexed-list-indexed-bar li {
    padding: 0 10px; }
  .Yep-indexed-list-indexed-bar-over {
    background-color: rgba(0, 0, 0, 0.65); }

.Yep-indexed-list-indicator {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 60px;
  margin: -30px auto auto -60px;
  background: transparent;
  opacity: 0.7;
  color: #ec3838;
  font-size: 40px;
  border-radius: 60px;
  z-index: 1999;
  text-align: center;
  line-height: 60px; }
  .Yep-indexed-list-indicator-hide {
    display: none; }

.Yep-list-item.Yep-input-item {
  height: 88px;
  padding-left: 30px; }

.Yep-list-item:not(:last-child) .Yep-list-line {
  border-bottom: 1PX solid #e3e5e9; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    html:not([data-scale]) .Yep-list-item:not(:last-child) .Yep-list-line {
      border-bottom: none; }
      html:not([data-scale]) .Yep-list-item:not(:last-child) .Yep-list-line::after {
        content: '';
        position: absolute;
        background-color: #e3e5e9;
        display: block;
        z-index: 1;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1PX;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5); } }
    @media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
      html:not([data-scale]) .Yep-list-item:not(:last-child) .Yep-list-line::after {
        -webkit-transform: scaleY(0.33);
                transform: scaleY(0.33); } }

.Yep-list-item .Yep-input-label {
  color: #3d3f52;
  font-size: 32px;
  margin-left: 0;
  margin-right: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 0; }
  .Yep-list-item .Yep-input-label.Yep-input-label-2 {
    width: 64px; }
  .Yep-list-item .Yep-input-label.Yep-input-label-3 {
    width: 96px; }
  .Yep-list-item .Yep-input-label.Yep-input-label-4 {
    width: 128px; }
  .Yep-list-item .Yep-input-label.Yep-input-label-5 {
    width: 160px; }
  .Yep-list-item .Yep-input-label.Yep-input-label-6 {
    width: 192px; }
  .Yep-list-item .Yep-input-label.Yep-input-label-7 {
    width: 224px; }

.Yep-list-item .Yep-input-control {
  font-size: 32px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1; }
  .Yep-list-item .Yep-input-control input {
    color: #3d3f52;
    font-size: 32px;
    -webkit-appearance: none;
            appearance: none;
    width: 100%;
    padding: 4px 0;
    border: 0;
    background-color: transparent;
    line-height: 1;
    box-sizing: border-box; }
    .Yep-list-item .Yep-input-control input::-webkit-input-placeholder {
      color: #c6c7ce;
      line-height: 1.2; }
    .Yep-list-item .Yep-input-control input::placeholder {
      color: #c6c7ce;
      line-height: 1.2; }
    .Yep-list-item .Yep-input-control input:disabled {
      color: #999baa;
      background-color: #fff; }

.Yep-list-item .Yep-input-clear {
  display: none;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  font-style: normal;
  color: #fff;
  margin: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .Yep-list-item .Yep-input-clear-active {
    background-color: #ec3838; }

.Yep-list-item.Yep-input-focus .Yep-input-clear {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex; }

.Yep-list-item .Yep-input-extra {
  -webkit-box-flex: initial;
  -webkit-flex: initial;
          flex: initial;
  min-width: 0;
  max-height: 42px;
  overflow: hidden;
  padding-right: 0;
  line-height: 1;
  color: #999baa;
  font-size: 24px;
  margin-left: 10px; }

.Yep-list-item.Yep-input-error .Yep-input-control input {
  color: #ED2945; }

.Yep-list-item.Yep-input-error .Yep-input-error-extra {
  height: 42px;
  width: 42px;
  margin-left: 12px;
  background-size: 42px auto; }

.Yep-list-item.Yep-input-disabled .Yep-input-label {
  color: #999baa; }

@charset "UTF-8";
.Yep-list-header {
  padding: 30px 30px 18px 30px;
  font-size: 28px;
  color: #999baa;
  width: 100%;
  box-sizing: border-box; }

.Yep-list-footer {
  padding: 18px 30px 30px 30px;
  font-size: 28px;
  color: #999baa; }

.Yep-list-body {
  position: relative;
  background-color: #fff;
  position: relative;
  position: relative; }
  .Yep-list-body:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 1PX;
    background-color: #e3e5e9;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    pointer-events: none; }
  .Yep-list-body:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1PX;
    background-color: #e3e5e9;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    pointer-events: none; }
  .Yep-list-body div:not(:last-child) .Yep-list-line {
    position: relative; }
    .Yep-list-body div:not(:last-child) .Yep-list-line:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }

.Yep-list-item.Yep-list-item-disabled .Yep-list-line .Yep-list-content,
.Yep-list-item.Yep-list-item-disabled .Yep-list-line .Yep-list-extra {
  color: #bbb; }

.Yep-list-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding-left: 30px;
  min-height: 88px;
  background-color: #fff;
  vertical-align: middle;
  overflow: hidden;
  -webkit-transition: background-color 200ms;
  transition: background-color 200ms;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  /* list左图片显示*/ }
  .Yep-list-item.Yep-list-item-top .Yep-list-line {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .Yep-list-item.Yep-list-item-top .Yep-list-line .Yep-list-arrow {
      margin-top: 4px; }
  .Yep-list-item.Yep-list-item-middle .Yep-list-line {
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center; }
  .Yep-list-item.Yep-list-item-bottom .Yep-list-line {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
            align-items: flex-end; }
  .Yep-list-item.Yep-list-item-error .Yep-list-line .Yep-list-extra {
    color: #f50; }
    .Yep-list-item.Yep-list-item-error .Yep-list-line .Yep-list-extra .Yep-list-brief {
      color: #f50; }
  .Yep-list-item.Yep-list-item-active {
    background-color: #ddd; }
  .Yep-list-item img {
    width: 44px;
    height: 44px;
    vertical-align: middle; }
  .Yep-list-item .Yep-list-thumb:first-child {
    margin-right: 30px; }
  .Yep-list-item .Yep-list-thumb:last-child {
    margin-left: 16px; }
  .Yep-list-item .Yep-list-line {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    -webkit-align-self: stretch;
            align-self: stretch;
    padding-right: 30px;
    overflow: hidden;
    /* list左侧主内容*/
    /* list右补充内容*/
    /* 辅助性文字*/
    /* list右侧箭头*/ }
    .Yep-list-item .Yep-list-line .Yep-list-content {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
              flex: 1;
      color: #3d3f52;
      font-size: 32px;
      line-height: 1.5;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 14px;
      padding-bottom: 14px; }
    .Yep-list-item .Yep-list-line .Yep-list-extra {
      -webkit-flex-basis: 36%;
              flex-basis: 36%;
      color: #999baa;
      font-size: 32px;
      line-height: 1.5;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 14px;
      padding-bottom: 14px; }
    .Yep-list-item .Yep-list-line .Yep-list-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .Yep-list-item .Yep-list-line .Yep-list-brief {
      color: #6c707d;
      font-size: 24px;
      line-height: 1.5;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .Yep-list-item .Yep-list-line .Yep-list-arrow {
      display: block;
      width: 30px;
      height: 30px;
      margin-left: 16px;
      visibility: hidden; }
      .Yep-list-item .Yep-list-line .Yep-list-arrow-horizontal {
        visibility: visible; }
      .Yep-list-item .Yep-list-line .Yep-list-arrow-vertical {
        visibility: visible;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .Yep-list-item .Yep-list-line .Yep-list-arrow-vertical-up {
        visibility: visible;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
      .Yep-list-item .Yep-list-line .Yep-list-arrow .Yep-icon {
        color: #C7C7CC; }
    .Yep-list-item .Yep-list-line-multiple {
      padding: 25px 30px 25px 0; }
      .Yep-list-item .Yep-list-line-multiple .Yep-list-content {
        padding-top: 0;
        padding-bottom: 0; }
      .Yep-list-item .Yep-list-line-multiple .Yep-list-extra {
        padding-top: 0;
        padding-bottom: 0; }
    .Yep-list-item .Yep-list-line-wrap .Yep-list-content {
      white-space: normal; }
    .Yep-list-item .Yep-list-line-wrap .Yep-list-extra {
      white-space: normal; }
  .Yep-list-item select {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    font-size: 34px;
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent; }

.Yep-mask {
  position: fixed;
  z-index: 222;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  height: 100%; }

.Yep-mask_transparent {
  position: fixed;
  z-index: 222;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%; }

.Yep-message .icon {
  width: 24px;
  height: 24px;
  background-size: 100% auto;
  background-repeat: no-repeat; }

.Yep-message .icon-close {
  width: 16px;
  height: 16px; }

.Yep-message.fix-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

.Yep-message.fix-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; }

.Yep-message.in-place {
  position: relative; }

.Yep-message .box {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px; }
  .Yep-message .box-info {
    color: #fff;
    background: #2e6da4; }
  .Yep-message .box-warn {
    color: #fff;
    background: #f0ad4e; }
  .Yep-message .box-error {
    color: #fff;
    background: #d9534f; }

.Yep-message .icon {
  display: block;
  font-size: 24px; }

.Yep-message .message-text {
  display: block;
  min-height: 32px;
  line-height: 32px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  margin: 0 14px;
  font-size: 24px; }

.Yep-nav-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  height: 88px;
  background: #fff;
  color: #3d3f52; }
  .Yep-nav-bar-left, .Yep-nav-bar-title, .Yep-nav-bar-right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center; }
  .Yep-nav-bar-left {
    padding-left: 30px;
    font-size: 32px; }
    .Yep-nav-bar-left-icon {
      margin-right: 10px;
      display: inherit; }
    .Yep-nav-bar-left-close {
      margin-left: 22px;
      display: inherit; }
  .Yep-nav-bar-title {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 36px;
    white-space: nowrap; }
  .Yep-nav-bar-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .Yep-nav-bar-right-share {
      margin-right: 22px;
      display: inherit; }
    .Yep-nav-bar-right-content {
      font-size: 32px;
      margin-right: 30px; }

.Yep-popup-picker-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all .2s;
  transition: all .2s;
  background-color: #fff;
  text-align: center;
  font-size: 14px; }

.Yep-popup-picker-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1PX solid #e3e5e9; }
  .Yep-popup-picker-header-item {
    color: #ec3838;
    font-size: 32px;
    padding: 18px 30px;
    height: 84px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Yep-popup-picker-header-title {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    text-align: center;
    color: #000000; }
  .Yep-popup-picker-header-right {
    text-align: right; }

.Yep-picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  .Yep-picker-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    text-align: center; }

.Yep-picker-col {
  display: block;
  position: relative;
  height: 476px;
  overflow: hidden;
  width: 100%; }
  .Yep-picker-col-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    padding: 204px 0; }
  .Yep-picker-col-item {
    touch-action: manipulation;
    text-align: center;
    font-size: 32px;
    height: 68px;
    line-height: 68px;
    color: #3d3f52;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .Yep-picker-col-item-selected {
      font-size: 36px; }
  .Yep-picker-col-mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
    background-position: top, bottom;
    background-size: 100% 204px;
    background-repeat: no-repeat; }
  .Yep-picker-col-indicator {
    box-sizing: border-box;
    width: 100%;
    height: 68px;
    position: absolute;
    left: 0;
    top: 204px;
    z-index: 3;
    border-top: 1PX solid #e3e5e9;
    border-bottom: 1PX solid #e3e5e9; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      html:not([data-scale]) .Yep-picker-col-indicator {
        border-top: none; }
        html:not([data-scale]) .Yep-picker-col-indicator::before {
          content: '';
          position: absolute;
          background-color: #e3e5e9;
          display: block;
          z-index: 1;
          top: 0;
          right: auto;
          bottom: auto;
          left: 0;
          width: 100%;
          height: 1PX;
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%;
          -webkit-transform: scaleY(0.5);
                  transform: scaleY(0.5); } }
    @media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
      html:not([data-scale]) .Yep-picker-col-indicator::before {
        -webkit-transform: scaleY(0.33);
                transform: scaleY(0.33); } }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      html:not([data-scale]) .Yep-picker-col-indicator {
        border-bottom: none; }
        html:not([data-scale]) .Yep-picker-col-indicator::after {
          content: '';
          position: absolute;
          background-color: #e3e5e9;
          display: block;
          z-index: 1;
          top: auto;
          right: auto;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1PX;
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          -webkit-transform: scaleY(0.5);
                  transform: scaleY(0.5); } }
    @media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
      html:not([data-scale]) .Yep-picker-col-indicator::after {
        -webkit-transform: scaleY(0.33);
                transform: scaleY(0.33); } }

.Yep-popover__trigger {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }

.Yep-popover__trigger--disabled,
.Yep-popover__trigger--disabled * {
  background-color: #eceff1 !important;
  border-color: #eaeaea !important;
  cursor: not-allowed !important;
  color: #999 !important;
  -webkit-user-select: none;
          user-select: none; }

.Yep-popover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999; }
  .Yep-popover:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0; }

.Yep-popover--animation {
  -webkit-animation: Yep-popover .15s;
          animation: Yep-popover .15s; }

@-webkit-keyframes Yep-popover {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Yep-popover {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Yep-popover--up {
  padding-bottom: 7px;
  margin-top: -7px; }
  .Yep-popover--up:after {
    left: 50%;
    bottom: 1px;
    margin-left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 6px solid #fff; }

.Yep-popover--down {
  padding-top: 7px; }
  .Yep-popover--down:after {
    left: 50%;
    top: 1px;
    margin-left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 6px solid #fff; }

.Yep-popover--left {
  padding-right: 7px; }
  .Yep-popover--left:after {
    top: 50%;
    right: 1px;
    margin-top: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 6px solid #fff; }

.Yep-popover--right {
  padding-left: 7px; }
  .Yep-popover--right:after {
    top: 50%;
    left: 1px;
    margin-top: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 6px solid #fff; }

.Yep-popover--align-left:after {
  left: 27px; }

.Yep-popover--align-right:after {
  left: auto;
  right: 27px; }

.Yep-popover--align-top:after {
  top: 27px; }

.Yep-popover--align-bottom:after {
  top: auto;
  bottom: 27px; }

.Yep-popover__content {
  padding: 10px 12px;
  background: #fff;
  border-radius: 2px;
  word-wrap: break-word;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  max-width: 200px;
  max-height: 300px;
  overflow: auto; }

.Yep-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 222;
  overflow: auto;
  outline: 0; }

.Yep-progress {
  border-radius: 50px;
  height: 10px;
  width: 100%;
  background: #f5f5f5;
  overflow: hidden; }
  .Yep-progress-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999; }
  .Yep-progress-bar {
    height: 10px;
    border-radius: 5px;
    -webkit-transition: width 1s;
    transition: width 1s; }

.Yep-radio {
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  .Yep-radio-inner {
    left: 15px;
    width: 36px;
    height: 36px;
    border: 1PX solid #ccc;
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    box-sizing: border-box;
    margin-right: 20px; }
    .Yep-radio-inner:after {
      position: absolute;
      display: none;
      top: -1px;
      right: 6px;
      z-index: 999;
      width: 10px;
      height: 22px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '\0020';
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .Yep-radio-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    -webkit-appearance: none;
            appearance: none; }
  .Yep-radio-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }
  .Yep-radio-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
            flex-direction: row; }
  .Yep-radio-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .Yep-radio.Yep-radio-checked .Yep-radio-inner {
    border-color: #ec3838;
    background: #ec3838; }
    .Yep-radio.Yep-radio-checked .Yep-radio-inner:after {
      display: block;
      border-color: #fff; }
  .Yep-radio.Yep-radio-disabled {
    opacity: 0.3; }
    .Yep-radio.Yep-radio-disabled.Yep-radio-checked .Yep-radio-inner {
      border-color: #999baa;
      background: none; }
      .Yep-radio.Yep-radio-disabled.Yep-radio-checked .Yep-radio-inner:after {
        border-color: #999baa; }

.Yep-rate {
  display: inline-block;
  position: relative; }
  .Yep-rate-star {
    float: right; }
    .Yep-rate-star-icon {
      font-size: 50px; }
  .Yep-rate-full-star, .Yep-rate-empty-star {
    color: #ec3838; }

.Yep-scratch {
  position: relative;
  margin: 0 auto;
  width: 445px;
  height: 120px; }
  .Yep-scratch .area, .Yep-scratch .area2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 445px;
    height: 120px;
    z-index: 100; }
  .Yep-scratch .area2 {
    z-index: 90; }

.yep-search-bar-bj {
  height: 100px;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  .yep-search-bar-bj .yep-search-bar-cancel {
    color: #ec3838;
    font-size: 28px;
    margin: 0 0 0 36px; }
  .yep-search-bar-bj .Yep-search-bar-wrapper {
    position: relative;
    height: 64px;
    background: #F0F0F0;
    width: 584px;
    border-radius: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 0 0 26px; }
    .yep-search-bar-bj .Yep-search-bar-wrapper .Yep-icon-lego_sousuo {
      margin: 0 20px 0 20px; }
    .yep-search-bar-bj .Yep-search-bar-wrapper .Yep-search-bar {
      padding-right: 25px;
      font-size: 32px;
      height: 64px;
      line-height: 64px;
      width: 490px;
      background-color: #F0F0F0;
      display: -webkit-box;
      display: flex;
      display: -webkit-flex; }
      .yep-search-bar-bj .Yep-search-bar-wrapper .Yep-search-bar::-webkit-input-placeholder {
        color: #C6C7CE; }
      .yep-search-bar-bj .Yep-search-bar-wrapper .Yep-search-bar::placeholder {
        color: #C6C7CE; }
    .yep-search-bar-bj .Yep-search-bar-wrapper .Yep-search-bar--sm + .Yep-clearable-search-bar__clear {
      right: 0; }

.Yep-search-bar__clear {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 10px; }

.Yep-swipe {
  overflow: hidden;
  position: relative; }
  .Yep-swipe-content {
    position: relative;
    background-color: #ffffff; }
  .Yep-swipe-cover {
    position: absolute;
    z-index: 2;
    background: transparent;
    height: 100%;
    width: 100%;
    top: 0;
    display: none; }
  .Yep-swipe .Yep-swipe-content,
  .Yep-swipe .Yep-swipe-actions {
    -webkit-transition: all 250ms;
    transition: all 250ms; }
  .Yep-swipe-swiping .Yep-swipe-swiping-content, .Yep-swipe-swiping .Yep-swipe-swiping-content {
    -webkit-transition: none;
    transition: none; }
  .Yep-swipe-swiping .Yep-list-item-active {
    background-color: #ffffff; }
  .Yep-swipe-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    white-space: nowrap; }
    .Yep-swipe-actions-left {
      left: 0; }
    .Yep-swipe-actions-right {
      right: 0; }
  .Yep-swipe-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden; }
    .Yep-swipe-btn-text {
      font-size: 28px;
      padding: 0 16px; }

.Yep-switch {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center; }
  .Yep-switch .checkbox {
    width: 100px;
    height: 60px;
    border-radius: 60px;
    box-sizing: border-box;
    background: #e5e5e5;
    z-index: 0;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
    .Yep-switch .checkbox:before {
      content: ' ';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 96px;
      height: 56px;
      border-radius: 56px;
      box-sizing: border-box;
      background: #fff;
      z-index: 1;
      -webkit-transition: all 200ms;
      transition: all 200ms;
      -webkit-transform: scale(1);
              transform: scale(1); }
    .Yep-switch .checkbox:after {
      content: ' ';
      height: 56px;
      width: 56px;
      border-radius: 56px;
      background: #fff;
      position: absolute;
      z-index: 2;
      left: 2px;
      top: 2px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: all 200ms;
      transition: all 200ms;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.21); }
    .Yep-switch .checkbox.checkbox-disabled {
      z-index: 3; }
  .Yep-switch input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    -webkit-appearance: none;
            appearance: none; }
    .Yep-switch input[type="checkbox"]:checked + .checkbox {
      background: #f23030; }
      .Yep-switch input[type="checkbox"]:checked + .checkbox:before {
        -webkit-transform: scale(0);
                transform: scale(0); }
      .Yep-switch input[type="checkbox"]:checked + .checkbox:after {
        -webkit-transform: translateX(40px);
                transform: translateX(40px); }
    .Yep-switch input[type="checkbox"]:disabled + .checkbox {
      opacity: 0.3; }

.Yep-tab-bar {
  position: fixed !important;
  left: 0;
  bottom: 0;
  z-index: 200;
  box-sizing: border-box;
  height: 102px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-transition: bottom .2s;
  transition: bottom .2s;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  .Yep-tab-bar:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 1PX;
    background-color: #e3e5e9;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    pointer-events: none; }
  .Yep-tab-bar-hidden {
    bottom: -102px; }
  .Yep-tab-bar-tab {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    width: 100%; }
    .Yep-tab-bar-tab-image {
      width: 50px;
      height: 50px;
      vertical-align: middle; }
    .Yep-tab-bar-tab-title {
      font-size: 24px;
      margin: 6px 0 0 0;
      line-height: 1;
      text-align: center; }
    .Yep-tab-bar-tab-icon {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .Yep-tab-bar-tab-icon .tab-badge :last-child {
        margin-top: 4px;
        left: 50px; }
      .Yep-tab-bar-tab-icon .tab-dot :last-child {
        margin-top: 4px;
        left: 50px; }

.Yep-tabs {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%; }
  .Yep-tabs * {
    box-sizing: border-box; }
  .Yep-tabs-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    width: 100%;
    height: 100%; }
    .Yep-tabs-content-wrap-animated {
      -webkit-transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
      transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
      transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
      transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1); }
  .Yep-tabs-pane-wrap {
    width: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    overflow-y: auto; }
  .Yep-tabs-tab-bar-wrap {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .Yep-tabs-horizontal .Yep-tabs-pane-wrap-active {
    height: auto; }
  .Yep-tabs-horizontal .Yep-tabs-pane-wrap-inactive {
    height: 0;
    overflow: visible; }
  .Yep-tabs-vertical .Yep-tabs-content-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .Yep-tabs-vertical .Yep-tabs-tab-bar-wrap {
    height: 100%; }
  .Yep-tabs-vertical .Yep-tabs-pane-wrap {
    height: 100%; }
  .Yep-tabs-vertical .Yep-tabs-pane-wrap-active {
    overflow: auto; }
  .Yep-tabs-vertical .Yep-tabs-pane-wrap-inactive {
    overflow: hidden; }
  .Yep-tabs-top, .Yep-tabs-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .Yep-tabs-left, .Yep-tabs-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
            flex-direction: row; }

.Yep-tabs-default-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1; }
  .Yep-tabs-default-bar-tab {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 28px;
    height: 88px;
    line-height: 88px; }
    .Yep-tabs-default-bar-tab .Yep-badge .Yep-badge-text {
      top: -26px;
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px); }
    .Yep-tabs-default-bar-tab .Yep-badge .Yep-badge-dot {
      top: -12px;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
  .Yep-tabs-default-bar-tab-active {
    color: #ec3838; }
  .Yep-tabs-default-bar-underline {
    position: absolute;
    border: 1px #ec3838 solid; }
  .Yep-tabs-default-bar-animated .Yep-tabs-default-bar-content {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    will-change: transform; }
  .Yep-tabs-default-bar-animated .Yep-tabs-default-bar-underline {
    -webkit-transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), color 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), color 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    will-change: top, left, width, color; }
  .Yep-tabs-default-bar-top, .Yep-tabs-default-bar-bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-content, .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
              flex-direction: row; }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-prevpage, .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-prevpage {
      pointer-events: none;
      position: absolute;
      top: 0;
      display: block;
      width: 118px;
      height: 100%;
      content: ' ';
      left: 0;
      background: -webkit-linear-gradient(left, white, rgba(255, 255, 255, 0));
      background: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-nextpage, .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-nextpage {
      pointer-events: none;
      position: absolute;
      top: 0;
      display: block;
      width: 118px;
      height: 100%;
      content: ' ';
      right: 0;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), white);
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white); }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-tab, .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-tab {
      padding: 8px 0; }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-underline, .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-underline {
      bottom: 0; }
  .Yep-tabs-default-bar-top .Yep-tabs-default-bar-tab {
    position: relative; }
    .Yep-tabs-default-bar-top .Yep-tabs-default-bar-tab:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-tab {
    position: relative; }
    .Yep-tabs-default-bar-bottom .Yep-tabs-default-bar-tab:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
      pointer-events: none; }
  .Yep-tabs-default-bar-left, .Yep-tabs-default-bar-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .Yep-tabs-default-bar-left .Yep-tabs-default-bar-content, .Yep-tabs-default-bar-right .Yep-tabs-default-bar-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .Yep-tabs-default-bar-left .Yep-tabs-default-bar-tab, .Yep-tabs-default-bar-right .Yep-tabs-default-bar-tab {
      padding: 0 8px; }
  .Yep-tabs-default-bar-left .Yep-tabs-default-bar-underline {
    right: 0; }
  .Yep-tabs-default-bar-left .Yep-tabs-default-bar-tab {
    position: relative; }
    .Yep-tabs-default-bar-left .Yep-tabs-default-bar-tab:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      left: 100%;
      top: 0;
      width: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleX(0.5);
      transform: scaleX(0.5);
      pointer-events: none; }
  .Yep-tabs-default-bar-right .Yep-tabs-default-bar-underline {
    left: 0; }
  .Yep-tabs-default-bar-right .Yep-tabs-default-bar-tab {
    position: relative; }
    .Yep-tabs-default-bar-right .Yep-tabs-default-bar-tab:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      right: 100%;
      top: 0;
      width: 1PX;
      background-color: #e3e5e9;
      -webkit-transform: scaleX(0.5);
      transform: scaleX(0.5);
      pointer-events: none; }

.Yep-to-top {
  position: fixed;
  right: 60px;
  bottom: 60px; }
  .Yep-to-top-content {
    width: 70px;
    height: 70px;
    outline: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    border-radius: 50%;
    box-shadow: 2px 2px 2px 2px #e3e5e9; }
    .Yep-to-top-content:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      box-sizing: border-box;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border: 1PX solid #e3e5e9;
      border-radius: 50%; }

.Yep-toast {
  position: fixed;
  width: 100%;
  top: 40%;
  z-index: 777;
  font-size: 32px;
  text-align: center; }
  .Yep-toast.mask {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Yep-toast.bottom {
    top: 86%; }
  .Yep-toast-notice {
    padding: 30px 40px;
    border-radius: 12px;
    color: #fff;
    min-width: 208px;
    background: rgba(0, 0, 0, 0.7); }
    .Yep-icon + .Yep-toast-notice-message {
      margin-top: 25px; }
  .Yep-toast .Yep-icon {
    /*&.shop-zhengque{
      color: #1aad19;
    }
    &.shop-zhuyi{
      color: $brand-primary;
    }*/ }

.Yep-upload-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.upload-flex-item {
  position: relative;
  margin-top: 10px;
  margin-right: 10px; }
  .upload-flex-item-img {
    background-size: 100% auto; }
  .upload-flex-item-del {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.3) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAEsAAABLABziOlSAAAAB3RJTUUH3gULCQEnSMFgKwAAAdJJREFUSMed1sFqE1EUxvFfewNWs+gkdGXFRAI+jF241upKreBG3bhpTR9AtLWCC19FEJUW6soHkGK7F5KANkKFupg7MIwzyYwfhCFn5v6/c+7cc5KFQa//Fd+xgR+ijk6ONdGg189/XcE7XFvEIdbwAp2KBU3gnchaw5fQTZKPuIiHuIxP+A3dJDGajJvCd3AXr7EZuklyhoNostHEpAJ+J8KH+BW6SQJn2G9iMgf+HKcQRpOxpiZ14Ucnx0K2OCozuVRlknt2JhxGk3FqMJqMlVRSalIB3ytmnlUb8isamHTwKp6WPWzl4Xm1VOs0ZgWPC9dSeJlCMVCxXW3cx1XcwG28KcLLun9WBflKNnEBj2LsbYxNZ8FhsYYBLGEZ5/GzHGNzFcqChXPexS5uSY/iIe7hivTFT6nu+FADvoP1aLKFD9JmfFDHJMyAdyJ0PZoMpcf0j/TFL9UxCQ3g09z9vEnWJ5/LTMJ/wKtMVstMQl141v6FPslMKgdkqwK+i+2KzIuaxkTgabw+wQiy34MifKhkcOU1p5LVrJLQTZIVvJROxX/g8zRnu/Zb0ra/Gfd824zB1WC7zqWDsb0w6PXf4xue4WfdzMuUOzBt6T+L638BGDnyCusOVvMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDQtMTBUMDM6MTU6NTMrMDg6MDCLJfsyAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE0LTA1LTExVDA5OjAxOjM5KzA4OjAw9jWoEgAAAEN0RVh0c29mdHdhcmUAL3Vzci9sb2NhbC9pbWFnZW1hZ2ljay9zaGFyZS9kb2MvSW1hZ2VNYWdpY2stNy8vaW5kZXguaHRtbL21eQoAAABjdEVYdHN2Zzpjb21tZW50ACBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIG47z/cAAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADEzNSTWDHwAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMTM1tydcIQAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxMzk5NzcwMDk5xXSLkQAAABF0RVh0VGh1bWI6OlNpemUAMjgzMEIeliCmAAAAX3RFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vaG9tZS93d3dyb290L3NpdGUvd3d3LmVhc3lpY29uLm5ldC9jZG4taW1nLmVhc3lpY29uLmNuL3NyYy8xMTYyMy8xMTYyMzI4LnBuZ8nZwRAAAAAASUVORK5CYII=") no-repeat center center;
    background-size: 50% 50%; }

.upload-add-picker {
  position: relative;
  overflow: hidden;
  border: solid 1PX #ddd; }
  .upload-add-picker input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 9; }
  .upload-add-picker:before {
    width: 1PX;
    height: 50px;
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #ccc; }
  .upload-add-picker:after {
    height: 1PX;
    width: 50px;
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #ccc; }

.Yep-whitespace-xs {
  height: 6px; }

.Yep-whitespace-sm {
  height: 12px; }

.Yep-whitespace-md {
  height: 18px; }

.Yep-whitespace-lg {
  height: 30px; }

.Yep-whitespace-xl {
  height: 42px; }
